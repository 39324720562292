<template>
  <div class="c-product-data">
     <div class="c-product-data__grid table-form">
       <div class="c-product-grid">
         <div class="c-product-grid__row c-product-grid__row--header table-form__header">
           <div class="c-product-grid__item" :class="isRequired('articleNumber')">
              {{ $t('Article Number') }}
           </div>
           <div class="c-product-grid__item" :class="isRequired('productDescription')">
             {{ $t('Product Description') }}
           </div>
           <div class="c-product-grid__item" :class="isRequired('lotNumber')">
             {{ $t('Lot number') }}
           </div>
           <div class="c-product-grid__item" :class="isRequired('expireDate')">
             {{ $t('Expire Date') }}
           </div>
           <div class="c-product-grid__item" :class="isRequired('amount')">
             {{ $t('Amount') }}
           </div>
           <div
             class="c-product-grid__item"
             :class="isRequired('orderNumber')"
             v-show="productAttributesOptions.orderNumber.required"
           >
             {{ $t('Order Number') }}
           </div>
           <div
             class="c-product-grid__item"
             :class="isRequired('deliveryDate')"
             v-show="productAttributesOptions.deliveryDate.required"
           >
             {{ $t('Delivery Date') }}
           </div>
           <div class="c-product-grid__item c-product-grid__item--empty">
           </div>
         </div>

         <div
           class="c-product-grid__row"
           v-for="(product, pKey, pIndex) in localProducts"
           :key="pIndex"
         >
           <div
             class="c-product-grid__item"
             v-for="(productAttribute, paKey, paIndex) in product"
             :key="paIndex"
             v-show=productAttributesOptions[paKey].required
           >
             <ProductAttribute
               :value="productAttribute"
               @input="updateAttribute(pKey, paKey, $event)"
               :name="paKey"
               :isRequired=productAttributesOptions[paKey].required
             />
           </div>
           <div
             class="c-product-grid__item c-product-grid__item--empty"
           >
             <div
               class="remove-icon"
               v-on:click="removeLine(pKey)"
             >
               <RemoveIcon class="icon" />
             </div>
           </div>
         </div>
       </div>
     </div>

    <div class="c-product-data__add">
      <div class="wrapper">
        <b-btn
          class="btn-add"
          v-on:click="addLine"
          :title="$t('Add product')"
        >
          <PlusIcon class="icon" />
        </b-btn>
        <span class="description">
          {{ $t('Add extra product') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ProductAttribute from '@/components/ProductAttribute.vue';
import ValidateState from '@/mixin/validateState';
import PlusIcon from '../assets/icon-plus.svg';
import RemoveIcon from '../assets/icon-remove.svg';

export default {
  name: 'ProductData',
  mixins: [
    ValidateState,
  ],
  components: {
    PlusIcon,
    RemoveIcon,
    ProductAttribute,
  },
  model: {
    prop: 'products',
  },
  props: {
    products: Array,
    reason: String,
  },
  data: () => ({
    localProducts: Object,
    productAttributesOptions: {
      articleNumber: {
        required: true,
      },
      productDescription: {
        required: true,
      },
      lotNumber: {
        required: true,
      },
      expireDate: {
        required: true,
      },
      amount: {
        required: true,
      },
      orderNumber: {
        required: true,
      },
      deliveryDate: {
        required: true,
      },
    },
  }),
  methods: {
    addLine() {
      const product = {
        articleNumber: '',
        productDescription: '',
        lotNumber: '',
        expireDate: '',
        amount: '',
        orderNumber: '',
        deliveryDate: '',
      };

      this.localProducts.push(product);
      this.update();
    },
    update(pKey) {
      this.$store.commit('updateProduct', { key: pKey, product: this.localProducts[pKey] });
    },
    updateAttribute(pKey, paKey, value) {
      this.localProducts[pKey][paKey] = value;
      this.update(pKey);
    },
    isRequired(attributeName) {
      return {
        'is-required': this.productAttributesOptions[attributeName].required,
      };
    },
    removeLine(pKey) {
      this.localProducts.splice(pKey, 1);
      this.update();
    },
  },
  computed: {
  },
  watch: {
    reason(value) {
      this.productAttributesOptions.orderNumber.required = (value !== 'recall');
      this.productAttributesOptions.deliveryDate.required = (value !== 'recall');
    },
  },
  created() {
    if (this.localProducts && this.localProducts.length < 1) {
      this.addLine();
    }
  },
  mounted() {
    this.localProducts = _.cloneDeep(this.products);
  },
};
</script>

<style lang="scss">
  .c-product-grid {
    $self: &;

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.3rem;

      &--header {
        #{ $self }__item {
          color: white;
          background: $blue;

          &--empty {
            background: none;
          }
        }
      }
    }

    &__item {
      flex-grow: 1;
      width: calc(95% / 7);
      margin-right: 0.3rem;
      padding: 0.2rem 0.3rem;
      font-size: 0.75rem;
      background: $grey-light;
      word-break: break-word;

      &:last-child {
        margin-right: 0;
        width: 5%;
      }

      &--empty {
        background: none;
      }
    }
  }
  .c-product-data__add {
    .wrapper {
      display: flex;
      align-items: center;
    }
    .description {
      color: $orange;
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
  }

  .remove-icon {
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
    }
  }
</style>

import * as emailjs from 'emailjs-com';

class MailService {
  private readonly serviceId: string;

  private readonly userId: string;

  constructor() {
    this.userId = 'user_NHjVs5XX06aBqRn456NHI';
    this.serviceId = 'service_6p5n078';

    emailjs.init(this.userId);
  }

  sendSubmissionMail(params: any) {
    const products = params.products;
    const mailJsTemplateId = params.clientContactData.country === 'nl' ? 'template_fbq7p5b' : 'fresenius_kabi_return_form_mail';

    const markup = `
     <table>
        <tr>
            <td>Artikel-nummer</td>    
            <td>Omschrijving</td>    
            <td>Lot-nummer</td>    
            <td>Vervaldatum</td>    
            <td>Aantal</td>    
            <td>Ordernummer</td>    
            <td>Leveringsdatum</td>    
        </tr>
        ${products.map((product: any) => `<tr><td>${product.articleNumber}</td><td>${product.productDescription}</td><td>${product.lotNumber}</td><td>${product.expireDate}</td><td>${product.amount}</td><td>${product.orderNumber}</td><td>${product.deliveryDate}</td></tr>`).join('')}
     </table>
    `;

    const templateParams = {
      clientContactData: params.clientContactData,
      explanation: params.explanation,
      declaration: params.declaration,
      productsMarkup: markup.replace(/\s+/g, ''),
      products: params.products,
    };

    this.send(mailJsTemplateId, templateParams);
  }

  send(templateId: string, templateParams: Record<string, unknown>) {
    return emailjs.send(this.serviceId, templateId, templateParams);
  }
}
export default new MailService();

<template>
  <div class="l-wrapper">
    <b-form-group>
      <b-form-input
        name="clientNumber"
        :value="contactData.clientNumber"
        @input="update('clientNumber', $event)"
        :placeholder="$t('Clientnumber')"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="name"
        :value="contactData.name"
        @input="update('name', $event)"
        :placeholder="$t('Name') + '*'"
        v-validate="{ required: true }"
        :state="validateState('name')"
        aria-describedby="name-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="name-live-feedback">
        {{ $t("This is a required field") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="companyName"
        :value="contactData.companyName"
        @input="update('companyName', $event)"
        :placeholder="$t('Company Name') + '*'"
        v-validate="{ required: true }"
        :state="validateState('companyName')"
        aria-describedby="companyName-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="companyName-live-feedback">
        {{ $t("This is a required field") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="function"
        :value="contactData.function"
        @input="update('function', $event)"
        :placeholder="$t('Function') + '*'"
        v-validate="{ required: true }"
        :state="validateState('function')"
        aria-describedby="function-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="function-live-feedback">
        {{ $t("This is a required field") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="address"
        :value="contactData.address"
        @input="update('address', $event)"
        :placeholder="$t('Address')"
        :state="validateState('address')"
        aria-describedby="address-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="address-live-feedback"> </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="email"
        :value="contactData.email"
        @input="update('email', $event)"
        :placeholder="$t('E-mail') + '*'"
        type="email"
        v-validate="{ required: true, email: true }"
        :state="validateState('email')"
        aria-describedby="email-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="email-live-feedback">
        {{ $t("This is an invalid e-mail") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="pickupAddress"
        :value="contactData.pickupAddress"
        @input="update('pickupAddress', $event)"
        :placeholder="$t('Pickup Address') + '*'"
        v-validate="{ required: true }"
        :state="validateState('pickupAddress')"
        aria-describedby="pickupAddress-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="pickupAddress-live-feedback">
        {{ $t("This is a required field") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="phone"
        :value="contactData.phone"
        @input="update('phone', $event)"
        :placeholder="$t('Phone number') + '*'"
        v-validate="{ required: true }"
        :state="validateState('phone')"
        aria-describedby="phone-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="phone-live-feedback">
        {{ $t("This is a required field") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="l-col">
      <div class="mod-select-box">
        <b-form-select
          name="country"
          :value="contactData.country"
          @input="update('country', $event)"
          :options="countryOptions"
          v-validate="{ required: true }"
          :state="validateState('country')"
          aria-describedby="country-live-feedback"
        ></b-form-select>
        <b-form-invalid-feedback id="country-live-feedback">
          {{ $t("This is a required field") }}
        </b-form-invalid-feedback>
        <div class="select-button">
          <i class="icon"><ArrowIcon /></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import ValidateState from '@/mixin/validateState';
import ArrowIcon from '../assets/icon-arrow.svg';
import i18n from '../i18n';

export default {
  name: 'ClientContactData',
  data: () => ({
    countryOptions: [
      { value: null, text: `${i18n.tc('Please select a country')}*` },
      { value: 'be', text: i18n.tc('Belgium') },
      { value: 'nl', text: i18n.tc('The Netherlands') },
      { value: 'lu', text: i18n.tc('Luxembourg') },
    ],
  }),
  mixins: [
    ValidateState,
  ],
  components: {
    ArrowIcon,
  },
  model: {
    prop: 'contactData',
  },
  props: {
    contactData: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.contactData, [key]: value });
    },
  },
  inject: ['$validator'],
};
</script>

<style lang="scss"></style>

<template>
  <div class="v-return">
    <div class="c-return-form form">
      <b-form @submit.stop.prevent="onSubmit">
        <div class="c-return-form__inner">
          <p>{{ $t("* indicates a required field") }}</p>

          <b-form-fieldset
            class="c-return-form__contact-details form-wrapper"
            :label="$t('ContactData')"
          >
            <ClientContactData v-model="contactData" />
          </b-form-fieldset>

          <b-form-fieldset
            class="c-return-form__explanation form-wrapper"
            :label="$t('Explanation')"
          >
            <Explanation v-model="explanation" />
          </b-form-fieldset>

          <b-form-fieldset class="c-return-form__produtcs form-wrapper" :label="$t('Product Data')">
            <ProductData v-model="products" :reason="explanation.reason" />
          </b-form-fieldset>

          <b-form-fieldset
            class="c-return-form__declaration form-wrapper"
            :label="$t('Declaration')"
            v-if="showDeclaration"
          >
            <Declaration v-model="declaration" :reason="explanation.reason" />
          </b-form-fieldset>

          <hr />

          <b-form-group>
            <b-form-checkbox
              id="terms-and-conditions"
              v-model="acceptTerms"
              name="acceptTerms"
              value="accepted"
              unchecked-value="not_accepted"
              v-validate="{ required: true }"
              :state="validateState('acceptTerms')"
            >
              <span v-html="$t('I have read the privacy statement')"></span>
            </b-form-checkbox>
          </b-form-group>

          <p>
            {{ $t("Serialised products disclaimer") }}
          </p>
        </div>
        <div class="c-return-form__actions">
          <b-button type="submit" variant="primary">{{ $t("Submit") }}</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ValidateState from '@/mixin/validateState';
import ProductData from '@/components/ProductData.vue';
import Declaration from '@/components/Declaration.vue';
import ClientContactData from '@/components/ClientContactData.vue';
import Explanation from '@/components/Explanation.vue';
import MailService from '../services/mailService';
import Firebase from '../services/firebase';

export default {
  name: 'return',
  mixins: [ValidateState],
  components: {
    ProductData,
    Declaration,
    ClientContactData,
    Explanation,
  },
  data() {
    return {
      acceptTerms: null,
      errors: false,
    };
  },
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  methods: {
    async validate() {
      const validators = [];
      validators.push(this.$validator.validateAll());

      this.getChildren(this.$children).forEach((child) => {
        validators.push(child.$validator.validateAll());
      });

      return Promise.all(validators);
    },
    generateFileName(fileName) {
      return `${this.contactData.companyName}-${Date.now()}/${fileName}`;
    },
    getChildren(children) {
      let subChildren = [];

      subChildren = subChildren.concat(children);

      _.forEach(children, (child) => {
        if (child.$children.length) {
          subChildren = subChildren.concat(this.getChildren(child.$children));
        }
      });

      return subChildren;
    },
    async onSubmit() {
      // Validate.
      const result = await this.validate();
      if (result.includes(false)) {
        return;
      }

      const data = {
        clientContactData: _.cloneDeep(this.contactData),
        explanation: _.cloneDeep(this.explanation),
        declaration: _.cloneDeep(this.declaration),
        products: _.cloneDeep(this.products),
      };

      // Save files in firebase storage.
      if (this.explanation.file) {
        const explanationFile = await Firebase.storeFile(
          this.generateFileName(this.explanation.file.name),
          this.explanation.file,
        );
        data.explanation.file = await explanationFile.ref.getDownloadURL();
      }

      if (this.declaration.temperatureLogs) {
        const temperatureFile = await Firebase.storeFile(
          this.generateFileName(this.declaration.temperatureLogs.name),
          this.declaration.temperatureLogs,
        );
        data.declaration.temperatureLogs = await temperatureFile.ref.getDownloadURL();
      }

      // Store data in firebase database.
      await Firebase.addSubmission(data);

      // Send mail.
      await MailService.sendSubmissionMail(data);

      // Redirect to confirmation page.
      this.$router.push({ name: 'confirmation' });

      // Clean data
      await this.$store.dispatch('cleanData');
    },
    updateSubmission(value, key) {
      this.$store.commit('updateSubmission', { [key]: value });
    },
  },
  computed: {
    showDeclaration() {
      return !!(
        this.explanation.reason
        && this.explanation.reason !== 'recall'
        && this.explanation.reason !== 'expired'
      );
    },
    products() {
      return this.$store.state.submission.products;
    },
    contactData: {
      get() {
        return this.$store.state.submission.clientContactData;
      },
      set(value) {
        this.updateSubmission(value, 'clientContactData');
      },
    },
    explanation: {
      get() {
        return this.$store.state.submission.explanation;
      },
      set(value) {
        this.updateSubmission(value, 'explanation');
      },
    },
    declaration: {
      get() {
        return this.$store.state.submission.declaration;
      },
      set(value) {
        this.updateSubmission(value, 'declaration');
      },
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.v-return {
  min-height: 500px;
  padding: 4rem;
}

.c-return-form {
  &__inner {
    padding: 3rem 4rem;
    background: white;
  }

  &__actions {
    margin-top: 2rem;
    text-align: right;
  }

  &__contact-details {
    .l-wrapper {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-group {
          width: 49%;
          margin-bottom: 1rem;
        }

        .l-col {
          width: 49%;
          margin-bottom: 1rem;
          }
      }
    }
  }

  &__explanation {
    .l-wrapper {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .l-col {
          width: 49%;
          margin-bottom: 1rem;

          &.full-width {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    submission: {
      clientContactData: {
        clientNumber: '',
        name: '',
        companyName: '',
        function: '',
        address: '',
        email: '',
        pickupAddress: '',
        phone: '',
        country: null,
      },
      declaration: {
        damageFree: null,
        storedCorrectly: null,
        storageDeviations: '',
        temperatureLogs: null,
      },
      explanation: {
        reason: null,
        file: null,
        text: '',
      },
      products: [
        {
          articleNumber: '',
          productDescription: '',
          lotNumber: '',
          expireDate: '',
          amount: '',
          orderNumber: '',
          deliveryDate: '',
        },
      ] as any,
    },
  },
  mutations: {
    updateSubmission(state, submission) {
      Object.assign(state.submission, submission);
    },
    updateProduct(state, payload) {
      state.submission.products[payload.key] = payload.product;
    },
    cleanData(state, payload) {
      _.forEach(state.submission.declaration, (item: any, key: any) => {
        // @ts-ignore
        state.submission.declaration[key] = null;
      });

      _.forEach(state.submission.explanation, (item: any, key: any) => {
        // @ts-ignore
        state.submission.explanation[key] = null;
      });

      const product = state.submission.products[0];
      state.submission.products = [];
      _.forEach(product, (item: any, key: any) => {
        // @ts-ignore
        product[key] = null;
      });
      state.submission.products.push(product);
    },
  },
  actions: {
    cleanData(context) {
      context.commit('cleanData');
    },
  },
});

import FirebaseApp from 'firebase/app';
import 'firebase/firestore';
import 'firebase/firebase-storage';

class Firebase {
  private app: any;

  private db: any;

  private storage: any;

  constructor() {
    const config = {
      apiKey: 'AIzaSyADuhYK5AaZxcUeYB6XiJDKbWW86Tskh3o',
      authDomain: 'fresenius-kabi-return-form.firebaseapp.com',
      databaseURL: 'https://fresenius-kabi-return-form.firebaseio.com',
      projectId: 'fresenius-kabi-return-form',
      storageBucket: 'gs://fresenius-kabi-return-form.appspot.com',
      messagingSenderId: '793881962320',
      appId: '1:793881962320:web:479e7e9243e36b0a',
    };

    this.app = FirebaseApp.initializeApp(config);
    this.db = this.app.firestore();
    this.storage = this.app.storage();

    // this.reference = db.ref('submissions');
  }

  addSubmission(data: any) {
    return this.db.collection('submissions').doc().set(data);
  }

  async storeFile(name: string, file: any) {
    const storageRef = this.storage.ref();
    return storageRef.child(name).put(file);

    // uploadTask.on('state_changed', (snapshot: any) => {
    // }, (error: any) => {
    //   console.log(error);
    // }, () => {
    //   // get the uploaded image url back
    //   const down = uploadTask.snapshot.ref.getDownloadURL();
    //   console.log(down);
    //   return down;
    // });
  }
}
export default new Firebase();

<template>
  <div class="c-header">
    <div class="c-header__logo">
      <router-link :to="{ name: 'intro' }">
        <img alt="Fresenius Kabi Logo" src="../assets/logo.png">
      </router-link>
    </div>
    <div class="c-header__language-switcher">
      <LanguageSwitcher />
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default {
  components: {
    LanguageSwitcher,
  },
};
</script>

<style lang="scss">
  .c-header {
    background: #0063BE;
    display: flex;
    justify-content: space-between;

    &__logo {
      background: white;
      width: 200px;
      padding: 0 2rem 1rem 0;

      img {
        width: 100%;
        height: auto;
      }
    }
    &__language-switcher {
      margin-top: 25px;
      margin-right: 35px;
    }
  }
</style>

<template>
  <div class="c-language-switcher">
    <a :href="'/' + language">
      {{ language }}
    </a>
  </div>
</template>

<script>
import i18n from '../i18n';

export default {
  name: 'LanguageSwitcher',
  computed: {
    language() {
      if (i18n.locale === 'nl') {
        return 'fr';
      }
      return 'nl';
    },
  },
};
</script>

<style lang="scss">
  .c-language-switcher {
    a {
      font-size: 1.2rem;
      color: white;
      padding-bottom: 3px;
      border-bottom: 1px solid white;
      text-transform: uppercase;

      &:hover {
        color: white;
        text-decoration: none;
        border-bottom-width: 5px;
        font-weight: bold;
        transition: border-bottom-width 0.3s, font-weight 0.3s;
      }
    }
  }
</style>

<template>
    <b-form-group>
      <b-form-input
        :value="value"
        @input="onChange"
        :name="name"
        v-validate="{ required: isRequired }"
        :state="validateState(name)"
      ></b-form-input>
    </b-form-group>
</template>

<script>
import ValidateState from '@/mixin/validateState';

export default {
  name: 'ProductLine',
  mixins: [
    ValidateState,
  ],
  inject: ['$validator'],
  props: {
    value: String,
    name: String,
    isRequired: Boolean,
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
  },
};

</script>

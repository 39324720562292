<template>
  <div class="c-declaration">

    <div
      class="c-declaration__item"
      v-show="showDamageFree"
    >
      <p>
        {{ $t('Product damage free description') }}
      </p>

      <div class="c-declaration__form-element">
        <b-form-group
          :label="$t('Product damage free') + '*'"
        >
          <b-form-radio-group
            id="damageFree"
            :checked="declaration.damageFree"
            @input="update('damageFree', $event)"
            :options="{true: $t('Yes'), false: $t('No')}"
            name="damageFree"
            class="form-radio-group"
            v-validate="{ required: showDamageFree}"
            :state="validateState('damageFree')"
            aria-describedby="damageFree-live-feedback"
          >
            <b-form-invalid-feedback id="damageFree-live-feedback">
              {{ $t('This is a required field') }}
            </b-form-invalid-feedback>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div class="c-declaration__item">
      <p>
        {{ $t('Stored correctly description') }}
      </p>

      <div class="c-declaration__form-element">
        <b-form-group
          :label="$t('Stored Correctly')"
        >
          <b-form-radio-group
            id="storedCorrectly"
            :checked="declaration.storedCorrectly"
            @input="update('storedCorrectly', $event)"
            :options="{true: $t('Yes'), false: $t('No')}"
            name="storedCorrectly"
            class="form-radio-group"
            v-validate="{ required: true}"
            :state="validateState('storedCorrectly')"
            aria-describedby="storedCorrectly-live-feedback"
          >
            <b-form-invalid-feedback id="storedCorrectly-live-feedback">
              {{ $t('This is a required field') }}
            </b-form-invalid-feedback>
          </b-form-radio-group>
        </b-form-group>
      </div>

      <div class="c-declaration__form-element">
        <b-form-group
          v-show="declaration.storedCorrectly === 'false'"
        >
          <b-form-textarea
            name="storageDeviations"
            id="textarea"
            :value="declaration.storageDeviations"
            @input="update('storageDeviations', $event)"
            :placeholder="$t('Storage deviations')"
            rows="4"
            max-rows="4"
            v-validate="{ required: declaration.storedCorrectly  === 'false'}"
            :state="validateState('storageDeviations')"
            aria-describedby="storageDeviations-live-feedback"
          ></b-form-textarea>
          <b-form-invalid-feedback id="storageDeviations-live-feedback">
            {{ $t('This is a required field') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="c-declaration__item">
      <p>
        {{ $t('Temperature logs description.p1') }}
      </p>

      <ul>
        <li>
          {{ $t('Temperature logs description.li1') }}
        </li>
        <li>
          {{ $t('Temperature logs description.li2') }}'
        </li>
      </ul>

      <div class="c-declaration__form-element">
        <b-form-group>
          <b-form-file
            name="temperatureLogs"
            :value="declaration.temperatureLogs"
            @input="update('temperatureLogs', $event)"
            :placeholder="$t('Choose a file...')"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import ValidateState from '@/mixin/validateState';

export default {
  name: 'Declaration',
  inject: ['$validator'],
  mixins: [
    ValidateState,
  ],
  components: {
  },
  model: {
    prop: 'declaration',
  },
  props: {
    declaration: Object,
    reason: String,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.declaration, [key]: value });
    },
  },
  computed: {
    selected() {
      return true;
    },
    showDamageFree() {
      return this.reason !== 'damaged';
    },
  },
};
</script>

<style lang="scss">
  .c-declaration {
    p {
      margin-bottom: 0.5rem;
    }

    &__item {
      margin-bottom: 2rem;
    }

    &__form-element {
      @include media-breakpoint-up(sm) {
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .form-radio-group[aria-invalid=true] {
    .invalid-feedback {
      display: block;
    }
  }
</style>

<template>
  <div class="l-wrapper">
    <div class="l-col">
      <div class="mod-select-box">
        <b-form-group>
          <b-form-select
            name="reason"
            :value="explanation.reason"
            @input="update('reason', $event)"
            :options="reasonOptions"
            v-validate="{ required: true }"
            :state="validateState('reason')"
            aria-describedby="reason-live-feedback"
          ></b-form-select>
          <b-form-invalid-feedback id="reason-live-feedback">
            {{ $t('This is a required field') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="select-button"><i class="icon"><ArrowIcon /></i></div>
      </div>
    </div>

    <div class="l-col">
      <b-form-group>
        <b-form-file
          :value="explanation.file"
          @input="update('file', $event)"
          :placeholder="$t('Choose a file...')"
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
    </div>

    <div class="full-width l-col">
      <b-form-group>
        <b-form-textarea
          name="text"
          id="textarea"
          :value="explanation.text"
          @input="update('text', $event)"
          :placeholder="$t('Remarks')"
          rows="6"
          max-rows="6"
          v-show="showRemarks"
        ></b-form-textarea>
        <b-form-invalid-feedback id="text-live-feedback">
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

  </div>
</template>

<script lang="js">

import ValidateState from '@/mixin/validateState';
import ArrowIcon from '../assets/icon-arrow.svg';
import i18n from '../i18n';

export default {
  name: 'Explanation',
  data: () => ({
    reasonOptions: [
      { value: null, text: `${i18n.tc('Please select an option')}*` },
      { value: 'damaged', text: i18n.tc('damaged') },
      { value: 'wrong-product', text: i18n.tc('wrong-product') },
      { value: 'wrong-order', text: i18n.tc('wrong-order') },
      { value: 'wrong-amount', text: i18n.tc('wrong-amount') },
      { value: 'expired', text: i18n.tc('expired') },
      { value: 'complaint', text: i18n.tc('complaint') },
      { value: 'recall', text: i18n.tc('recall') },
      { value: 'other', text: i18n.tc('other') },
    ],
  }),
  inject: ['$validator'],
  mixins: [
    ValidateState,
  ],
  components: {
    ArrowIcon,
  },
  model: {
    prop: 'explanation',
  },
  props: {
    explanation: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.explanation, [key]: value });
    },
  },
  computed: {
    showRemarks() {
      switch (this.explanation.reason) {
        case 'other':
        case 'wrong-product':
        case 'wrong-amount':
        case 'complaint':
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss">

</style>
